<template>
  <div class="col-sm-12">
    <div class="payment-option-card p-3">
      <h6>Payment Options</h6>
      <p>Enter your details below to use these methods of payment</p>
      <ul class="list-unstyled d-flex mb-0">
        <!-- <li><img src="@/assets/img/send-money/mtn.png" /></li> -->
        <li class="mx-2">
          <img src="@/assets/img/send-money/airtle-tigo.png" />
        </li>
        <li><img src="@/assets/img/send-money/vodafone.png" /></li>
      </ul>
    </div>
    <h5 class="mb-4">Your Payment Details</h5>
    <ValidationObserver v-slot="{ passes }">
      <form class="form-auth-small" @submit.prevent="passes(sendByMobileMoney)" autocomplete="off">
        <ValidationProvider
          name="selectcountry"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="country-dropdown mt-1">
            <div
              class="form-group form-select multiselect-grp"
              :class="{
                errors: errors[0],
                success:
                  mobileMoney.country &&
                  mobileMoney.country.length != 0 &&
                  !errors[0],
              }"
            >
              <span class="float-label">
                <label for="Country">Select Country*</label>
                <multiselect
                  placeholder=""
                  v-model="mobileMoney.country"
                  :show-labels="false"
                  :options="countrysOptions"
                  :searchable="false"
                  label="name"
                  track-by="name"
                >
                  <template slot="option" slot-scope="props">
                    <span :class="props.option.flagClass"></span>
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.name
                      }}</span>
                    </div>
                  </template>
                </multiselect>
                <i
                  class="fa fa-times"
                  v-if="errors.length && errors[0]"
                ></i>
                <i
                  class="fa fa-check"
                  v-if="
                    mobileMoney.country &&
                    mobileMoney.country.length != 0 &&
                    !errors[0]
                  "
                ></i>
              </span>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="selctNetwork"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="country-dropdown">
            <div
              class="form-group form-select single-multiselect"
              :class="{
                errors: errors[0],
                success:
                  mobileMoney.mobileNetwork &&
                  mobileMoney.mobileNetwork.length != 0 &&
                  !errors[0],
              }"
            >
              <span class="float-label">
                <label for="Country">Mobile Money Network*</label>
                <multiselect
                  placeholder=""
                  v-model="mobileMoney.mobileNetwork"
                  :show-labels="false"
                  :options="mobileNetworkOptions"
                  :searchable="false"
                  label="name"
                >
                </multiselect>
                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                <i
                  class="fa fa-check"
                  v-if="
                    mobileMoney.mobileNetwork &&
                    mobileMoney.mobileNetwork.length != 0 &&
                    !errors[0]
                  "
                ></i>
              </span>
            </div>
          </div>
        </ValidationProvider>
        <div
          class="form-group"
          :class="{
            errors: mobileMoney.mobileInputError == false,
            success: mobileMoney.mobileNumber && mobileMoney.mobileInputError,
          }"
        >
          <div class="float-label">
            <label for="primary_number" class="control-label"
              >Mobile Number*</label
            >
            <vue-tel-input
              v-model="mobileMoney.mobileNumber"
              v-bind="primaryMobileNumber"
              @validate="mobileInputValidate"
              ref="telInput"
              @keypress.native="onlyNumber($event)"
            >
              <template v-slot:arrow-icon>
                <span class="vti__dropdown-arrow">{{ "▼" }}</span>
              </template>
            </vue-tel-input>
            <div class="line"></div>
            <i
              class="fa fa-times"
              v-if="mobileMoney.mobileInputError == false"
            ></i>
            <i
              class="fa fa-check"
              v-if="mobileMoney.mobileInputError && mobileMoney.mobileNumber"
            ></i>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-lg btn-mw btn-block"  :disabled="btnDesabled">
          Pay by Mobile Money
        </button>
      </form>
    </ValidationObserver>
    <main-loader ref="loader"></main-loader>
    <b-modal id="timer_modal" centered hide-footer hide-header no-stacking no-close-on-backdrop @shown="resetTimer">
        <div class="modal-area">
            <ul class="list-unstyled text-center mb-0">
                <li class="d-flex align-items-center flex-column mb-3">
                    <i class="fa fa-5x fa-warning text-warning mr-3 blink"></i>
                    <h5 class="mb-0">This window will close in {{showtime}} minutes</h5>
                </li>
                <li>
                    <p class="text-center">
                        Please check your phone to authorise this payment.
                    </p>
                </li>
                <li class="my-5">
                    <div class="base-timer">
                        <svg class="base-timer__svg" viewBox="0 0 100 100">
                            <g class="base-timer__circle">
                                <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                <path :stroke-dasharray="circleDasharray" class="base-timer__path-remaining primary" 
                                d="
                                    M 50, 50
                                    m -45, 0
                                    a 45,45 0 1,0 90,0
                                    a 45,45 0 1,0 -90,0
                                "></path>
                            </g>
                        </svg>
                        <strong class="base-timer__label flex-column">{{ formattedTimeLeft }} <small>Sec</small></strong>
                    </div>
                </li>
                <!-- <li class="d-flex align-items-center flex-column">
                    <b-button class="btn-lg mb-2 w-50" size="lg" variant="primary" @click="generateToken()">Stay connected</b-button>
                    <b-button class="btn btn-outline-danger mr-1 btn-lg w-50" @click="exitProcess()">End session</b-button>
                </li> -->
            </ul>
        </div>
    </b-modal>

    <b-modal id="time_out" centered hide-footer hide-header no-stacking no-close-on-backdrop>
        <div class="modal-area">
            <ul class="list-unstyled text-center mb-0">
                <li class="d-flex align-items-center flex-column mb-3">
                    <i class="fa fa-5x fa-warning text-warning mr-3 blink"></i>
                    <h5 class="mb-0">This payment has timed out</h5>
                </li>
                <!-- <li>
                  <p class="text-center">
                    Please try again and make sure you confirm the payment
                  </p>
                </li> -->
                <li class="d-flex align-items-center flex-column">
                    <b-button class="btn-lg mb-2 w-50" size="lg" variant="primary" @click="returnMerchant()">Return To Merchant</b-button>
                </li>
            </ul>
        </div>
    </b-modal>
  </div>
</template>
<script>
const { VueTelInput } = require("vue-tel-input");
const { ValidationObserver, ValidationProvider } = require("vee-validate");
import parsePhoneNumber from "libphonenumber-js";
import shared from "@/shared.js";
const LoaderComponent = require("@/components/loader/LoaderComponent.vue").default;
const FULL_DASH_ARRAY = 283;
const TIME_LIMIT = 300; //180; //120;

export default {
  name: "MobileMoney",
  components: {
    ValidationObserver,
    ValidationProvider,
    VueTelInput,
    "main-loader": LoaderComponent,
    Multiselect: () => import("vue-multiselect"),
  },
  // props: {
  //   checkout_country: Object,
  // },
  data() {
    return {
      btnDesabled:false,
      timePassed: 0,
      timerInterval: null,

      showtime : TIME_LIMIT/60,

      mobileMoney: {
        country: {
          id:"",//"8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
          name: "",//"Ghana",
          flagClass: "",//"flag-icon flag-icon-gh fi-xl fi-round",
          primaryNumberCode: "",//"GH",
          alpha2: "",
          alpha3:"",
          currency:"",
          mobilecode:""
        },
        mobileNumber: "",
        mobileNetwork: null,
        mobileInputError: "",
        currency: {
          name: "",//"GHS",
          flagClass: "",//"flag-icon flag-icon-gh fi-md fi-round",
        },
        amount: "",
        disabledCurrencyDropDown: true,
      },


      countrysOptions: [
      //   {
      //     name: "United Kingdom",
      //     flagClass: "flag-icon flag-icon-gb fi-xl fi-round",
      //     primaryNumberCode: "GB",
      //     id:"8e60ff5a-dda4-45eb-b220-9c4dfcdb32a5"
      //   },
      //   {
      //     name: "Ghana",
      //     flagClass: "flag-icon flag-icon-gh fi-xl fi-round",
      //     primaryNumberCode: "GH",
      //     id:"8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
      //   },
      ],
      
      // currencyOptions: [
      //   { name: "GBP", flagClass: "flag-icon flag-icon-gb fi-md fi-round" },
      //   { name: "GHS", flagClass: "flag-icon flag-icon-gh fi-md fi-round" },
      //   // { name: "USD", flagClass: "flag-icon flag-icon-us fi-md fi-round" },
      //   // { name: "RUB", flagClass: "flag-icon flag-icon-ru fi-md fi-round" },
      // ],

      primaryMobileNumber: {
        mode: "auto",
        defaultCountry: "GH",
        autoFormat: true,
        dropdownOptions: {
          showFlags: true,
          disabled: true,
          showDialCodeInSelection: true,
        },
      },

      mobileNetworkOptions: [
        // {name:'MTN Prepaid Topup', value:'MTN'},
        // { name: "Vodafone", value: "Vodafone" },
        // { name: "Airtel-Tigo", value: "Airtel" },
      ],

      apiErrorCode: "",
      apiErrorMsg: "",
      userToken: "",

      interval: null,
      transactionId: '',

      merchantdata : '',
      countryID : '',

      callapi: true,
    };
  },
  methods: {
    onTimesUp() {
        clearInterval(this.timerInterval);
    },

    startTimer() {
        this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },

    //reset timer 
    resetTimer() {
        this.startTimer();
    },

    exitProcess(){
      sessionStorage.removeItem("settranid");
      sessionStorage.removeItem('amt');
      sessionStorage.removeItem('success');
      // sessionStorage.removeItem('error');
      sessionStorage.removeItem('country');
    },

    returnMerchant(){
      this.$bvModal.hide('time_out');
      const merchanturl = sessionStorage.getItem('merchanturl');
      sessionStorage.removeItem('error');
      window.location.href = merchanturl;
    },

    async getCountryData() {
      try {
        const response = await this.$http.get(this.$standappurl + "countries/source");
        // console.log(response);
        this.countrysOptions = [];
        // JSON responses are automatically parsed.
        response.data.details.map((item) => {
          if(item.countryname == 'Ghana'){
            this.mobileMoney.country = {
              id:item.countryid,
              name: item.countryname,
              flagClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
              primaryNumberCode: item.isocode,
              alpha2: item.alpha2,
              alpha3:item.alpha3,
              currency:item.currencycode,
              mobilecode:item.mobilecountrycode
            };

            let countryData = {
              id:item.countryid,
              name: item.countryname, 
              flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
              primaryNumberCode:item.isocode,
              alpha2:item.alpha2,
              alpha3:item.alpha3,
              currency:item.currencycode,
              mobilecode: item.mobilecountrycode
            }
            this.countrysOptions.push(countryData);
          }
        })
      } catch (error) {
        console.log(error);
      }
    },  

    async sendByMobileMoney() {
      if (typeof this.mobileMoney.mobileInputError == "undefined") {
        this.mobileMoney.mobileInputError = false;
      }
      if (this.mobileMoney.mobileInputError) {
        this.btnDesabled = true;
        const phoneNumber = parsePhoneNumber(
          this.mobileMoney.mobileNumber,
          this.mobileMoney.country.primaryNumberCode
        );
        // console.log(phoneNumber);
        let paramData = {
          country: this.mobileMoney.country.primaryNumberCode,
          account: phoneNumber.nationalNumber,
          providercode: this.mobileMoney.mobileNetwork.providercode //value,
        };
        // console.log(paramData);

        //globally defined in env file
        const username = this.$namecheckuser;
        const password = this.$namecheckpwd;
        
        // staging purpose (stand-app.com)
        // const username = "d87f9589f47a595bab9e34898001884d"; //"ee0a5a7a2888548783c04d6581919970";
        // const password = "0e7353dd4fe24ae7a3483ffd5b350237"; //"46b8e014830e4fbb89543ecb74bec5c8";

        // Production purpose (standapp.net)
        // const username = "33c5049b1f47521d8d8beddddfe19e74";
        // const password = "dbff06107e7e4213850f3a8cfd28529e";

        const token = Buffer.from(`${username}:${password}`).toString("base64");

        this.$http
          .get(this.$namecheckurl + "middleware/namecheck", {
            params: paramData,
            headers: {
              Authorization: `Basic ${token}`,
            },
          })
          .then((res) => {
            const totalResponse = {
              country: this.mobileMoney.country.name,
              account: phoneNumber.number,
              provider: this.mobileMoney.mobileNetwork.value,
              name: res.data.details ? res.data.details.name : "",
            };
            if (res.data.code == "0014-600") { //Number is valid with proper user's name
              shared.nameCheckConfirm(totalResponse).then((result) => {
                if (result.isConfirmed) {
                  this.confirmPayment();
                }else{
                  this.btnDesabled = false;
                }
              });
            }
          })
          .catch((error) => {
            // console.log(error.response.data);
            if (error.response.data.code == "E0014-601") { //If number is invalid
              const phoneNumber = parsePhoneNumber(
                this.mobileMoney.mobileNumber,
                this.mobileMoney.country.primaryNumberCode
              );
              const totalResponse = {
                country: this.mobileMoney.country.name,
                account: phoneNumber.number,
                provider: this.mobileMoney.mobileNetwork.value,
              };
              shared.invalidAlert(totalResponse);
              this.btnDesabled = false;
            } else if (error.response.data.code == "E0014-602") { //If number is valid but no user's name found
              const phoneNumber = parsePhoneNumber(
                this.mobileMoney.mobileNumber,
                this.mobileMoney.country.primaryNumberCode
              );
              const totalResponse = {
                country: this.mobileMoney.country.name,
                account: phoneNumber.number,
                provider: this.mobileMoney.mobileNetwork.value,
                name: "",
              };
              shared.nameCheckConfirm(totalResponse).then((result) => {
                if (result.isConfirmed) {
                  this.confirmPayment();
                } else{
                  this.btnDesabled = false;
                }
              });
            }
          });
      }
    },

    confirmPayment() {
      // shared.loaderAlert();
      this.$bvModal.show('timer_modal')
      // this.$refs.loader.show();
      const phoneNumber = parsePhoneNumber(
        this.mobileMoney.mobileNumber,
        this.mobileMoney.country.primaryNumberCode
      );
      const splitNumber = phoneNumber.number.split("+");
      const mobileNumber = splitNumber[1];

      const userdetail = JSON.parse(sessionStorage.getItem("userData"));
      if (this.mobileMoney.mobileNetwork.value == "Vodafone") {
        this.createLog(this.transactionId, 'Call Vodafone');
        let vodafoneData = {
          amount: sessionStorage.getItem('amt'),
          country: this.mobileMoney.country.alpha3, //this.mobileMoney.country.primaryNumberCode == "GH" ? "GHA" : "GBR",
          currency: sessionStorage.getItem('currency'), //this.mobileMoney.country.primaryNumberCode == "GH" ? "GHS" : "GBP",
          customer_mobile_number: mobileNumber, //"233266688826",
          service_provider_code: "100011",
          third_party_conversation_id: "asv02e5958774f7ba228d83d0d6897323",
          transaction_reference: "PayAngelProdTest323",
          purchased_items_desc: "B2C Transaction",
          merchant_id:this.transactionId,
          payment_provider: this.mobileMoney.mobileNetwork.id
        };
        // console.log(vodafoneData);
        this.updatePayment(vodafoneData);

        this.$http
          .post(this.$momobaseurl + "c2b-single-stage/", vodafoneData)
          .then((res) => {
            //Perform Success Action
            // console.log(res);
            if (res.data.code === "0046-000") {
              this.apiErrorMsg = "";
            } else {
              this.apiErrorMsg = "Invalid Mobile Number"; //res.data.message;
            }
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      } else if (this.mobileMoney.mobileNetwork.value == "Airtel-Tigo") {
        this.createLog(this.transactionId, 'Call Airtel-Tigo');
        let x = Math.floor(Math.random() * 100 + 1);
        const transactionId = String(x).padStart(13, "0");
        // let y = Math.floor(Math.random() * 100 + 1);
        // const referenceId = String(y).padStart(16, "0");
        let airtelData = {
          transactionID: transactionId, //"2435399942639",
          paymentReference: userdetail.reference, //referenceId, //"7112556943392639",
          country: sessionStorage.getItem('currency'), //this.mobileMoney.mobileISO == "GH" ? "GHS" : "GBP", //"GHS",
          amount: sessionStorage.getItem('amt'),
          correlationID: "3552990018",
          customer_account: mobileNumber, //"233277866303",
          initiator_account: "233277602999",
          merchant_id:this.transactionId,
          payment_provider: this.mobileMoney.mobileNetwork.id
        };
        // console.log(airtelData);
        this.updatePayment(airtelData);

        this.$http
          .post(this.$airtelbaseurl + "airtel/purchaseInitiate", airtelData)
          .then((res) => {
            //Perform Success Action
            // console.log(res);
            if (res.data.code === "0120-000") {
              this.apiErrorMsg = "";
              // this.$refs.loader.show();
              // shared.closeloaderalert();
            } else {
              this.btnDesabled = false;
              this.apiErrorMsg = "Invalid Mobile Number"; //res.data.message;
            }
          })
          .catch((error) => {
            // error.response.status Check status code
            console.log(error.response.data.code);
            this.btnDesabled = false;
            //this.$swal('Error!', error.response.data.message, 'error', 'Close');
            // shared.toastrError(error.response.data.message);
          });
      } else {
        console.log("submit Form");
      }
    },

    updatePayment(paymentReq) {
      // console.log(paymentReq);
      let paymentData = {
        paymentrequest: paymentReq,
        paymentproviderid: paymentReq.payment_provider
      };
      // console.log(paymentData, this.transactionId);
      this.$http
        .put(this.$standappurl + "payment/"+this.transactionId, paymentData)
        .then((res) => {
          console.log(res);
          this.callpaymentdetail();
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
    },

    getPaymentDetails() {
      const transactionid = this.transactionId;
      // console.log(paymentData);
      if(this.mobileMoney.mobileNetwork.value == "Airtel-Tigo"){
        const userdetail = JSON.parse(sessionStorage.getItem("userData"));
        let detailsData = {
          transactionID : transactionid,
          paymentReference : userdetail.reference,
          correlationID : "3552990018",
          customer_account :'233277602999'
        }
        this.$http.post(this.$airtelbaseurl + "airtel/GetPurchaseTransDetails",detailsData)
        .then((res) => {
          // Reversed, Expired, Declined, Initiated, Pending, Completed
          // console.log(res);
          if (res.data.status != "Initiated") {
            this.destroyedInterval;
            this.callapi = true;
            if(res.data.status == "Pending"){
              this.$bvModal.hide('timer_modal');
              shared.toastrError(res.data.detail);
            } else {
              if (res.data.status == "Completed") {
                this.updatePaymentStatus('Success');
                this.$bvModal.hide('timer_modal')
                this.$router.push({ path: '/success' });
              } else if (res.data.status == "Declined" || res.data.status == "Reversed" || res.data.status == "Expired") {
                this.updatePaymentStatus('Failure');
                shared.closeloaderalert();
                this.$router.push({ path: '/failure' });
              } else {
                shared.closeloaderalert();
              }
            }
          }
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
      } else {
        this.$http
        .get(this.$standappurl + "payment/details/" + transactionid)
        .then((res) => {
          if (res.data.details.status != "Processing") {
            this.destroyedInterval;
            this.callapi = false;
            if (res.data.details.status == "Success") {
              this.$bvModal.hide('timer_modal')
              this.$router.push({ path: '/success' });
            } else if (res.data.details.status == "Failure") {
              shared.closeloaderalert();
              this.$router.push({ path: '/failure' });
            } else {
              shared.closeloaderalert();
            }
          }
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
      }
    },

    getProvider(countryid){
      // const countryid = this.countryID;
      // console.log(paymentData);
      this.$http
        .get(this.$standappurl + "payment/provider/" + countryid)
        .then((res) => {
          // console.log(res);
          if(res.data.code == '0150-000'){
            this.mobileNetworkOptions = [];
            res.data.details.map((item) => {
              if(item.providername == 'Airtel-Tigo'){
                let providerData = {
                  id: item.providerid,
                  name: item.providername, 
                  value: item.providername,
                  providercode: item.providercode
                }
                this.mobileNetworkOptions.push(providerData);
              }
              // console.log(this.mobileNetworkOptions)
            });
            
          }
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
    },

    async updatePaymentStatus(statusvalue){
      let updateData = {
        status : statusvalue
      }
      // console.log(updateData);
      this.$http
        .put(this.$standappurl + "payment/status/" + this.transactionId, updateData)
        .then((res) => {
          console.log(res);
        }).catch((error) => {
          console.log(error.response);
        });
    },

    callpaymentdetail() {
      this.interval = setInterval(() => {
        if(this.callapi){
          this.getPaymentDetails();
        }
      }, 5000);
    },
    
    destroyedInterval() {
      clearInterval(this.interval);
    },

    onlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
      if (/^\W$/.test(event.key)) {
        event.preventDefault();
      }
    },

    mobileInputValidate(phoneObject) {
      this.mobileMoney.mobileInputError = phoneObject.valid;
    },

    setPrimaryNumberCountryCode(defaultCountry) {
      // console.log('defaultCountry',defaultCountry);
      this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
      this.mobileMoney.country.name = defaultCountry.name;
      this.mobileMoney.country.primaryNumberCode = defaultCountry.primaryNumberCode;
      this.mobileMoney.country.alpha3 = defaultCountry.alpha3;
    },

    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
    },

    confirmStayInDirtyForm() {
      return this.form_dirty && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },

    createLog(transactionid,pagename){
      let addLog = {
        transactionid : transactionid,
        pagename : pagename
      }
      this.$http.post(this.$standappurl + "payment/create/activitylog", addLog).then((response) => {
        console.log(response.data.code);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
    // console.log(this.merchantdata);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {
    if(sessionStorage.getItem('countryid')){
      this.transactionId = sessionStorage.getItem('settranid');
      this.countryID = sessionStorage.getItem('countryid');
      this.getProvider(sessionStorage.getItem('countryid'));
      this.getCountryData();
      this.createLog(sessionStorage.getItem('settranid'), 'Pay By MOMO');
    }
  },
  watch: {
    // checkout_country: function (result) {
    //   this.countryID = result.id;
    //   this.setPrimaryNumberCountryCode(result);
    //   this.getProvider(result.id);
    // },
    timeLeft(newValue) {
        if (newValue === 0) {
            this.onTimesUp();
        }
    }
  },computed: {
      circleDasharray() {
          return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
      },

      formattedTimeLeft() {
          const timeLeft = this.timeLeft;
          const minutes = Math.floor(timeLeft / 60);
          let seconds = timeLeft % 60;

          if (seconds < 10) {
              seconds = `0${seconds}`;
          }
          
          if( `${minutes}:${seconds}` == '0:00'){
              this.destroyedInterval();
              this.exitProcess();
              this.$bvModal.hide('timer_modal');
              this.$bvModal.show('time_out');
          }
          return `${minutes}:${seconds}`;
      },

      timeLeft() {
          return TIME_LIMIT - this.timePassed;
      },

      timeFraction() {
          const rawTimeFraction = this.timeLeft / TIME_LIMIT;
          return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
      }
  }
};
</script>
<style scoped>
@import "~vue-tel-input/dist/vue-tel-input.css";
</style>